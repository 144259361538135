import { useContext, useEffect, useState } from "react";
import CartContext from "../context/cart-context";
import { useStaticQuery, graphql } from "gatsby";

const useRushOrderProduct = () => {
  const cartContext = useContext(CartContext);
  const [rushOrderProduct, setRushOrderProduct] = useState(null);

  const rushOrderQuery = useStaticQuery(graphql`
    query RushOrderQuery {
      allWpProduct(filter: { product: { sku: { eq: "rush-order" } } }) {
        edges {
          node {
            title
            content
            date(formatString: "MMMM DD, YYYY")
            slug
            featuredImage {
              node {
                title
                localFile {
                  publicURL
                }
              }
            }
            product {
              mainH1
              basePrice
              salePrice
              sku
              taxable
              category
              type
              cartCalloutText
            }
            seo {
              title
              metaDesc
              canonical
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (cartContext.checkIfCartHasItemByUrl("/rush-order/")) {
      setRushOrderProduct(false);
    } else {
      setRushOrderProduct(rushOrderQuery.allWpProduct.edges[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rushOrderQuery, cartContext.contents]);

  return rushOrderProduct;
};
export default useRushOrderProduct;
